$globe-width: 8px;
$globe-height: 12px;
$globe-spacing: 15px;
$globe-spread: 3px;
$light-off-opacity: 0.2;

$globe-color-1: rgb(164 215 9);
$globe-color-2: rgb(100 126 235);
$globe-color-3: rgb(210 61 27);

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;

  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    padding: 0;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: calc($globe-spacing/2);
    display: inline-block;
    background: rgba($globe-color-1, 1);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-1, 1);
    animation-name: flash-1;
    animation-duration: 2.5s;

    &:nth-child(2n + 1) {
      background: rgba($globe-color-2, 1);
      box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-2, 0.5);
      animation-name: flash-2;
      animation-duration: 1.5s;
    }

    &:nth-child(4n + 2) {
      background: rgba($globe-color-3, 1);
      box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-3, 1);
      animation-name: flash-3;
      animation-duration: 1.75s;
    }

    &:nth-child(odd) {
      animation-duration: 1.7s;
    }

    &:nth-child(3n + 1) {
      animation-duration: 1.2s;
    }

    &::before {
      content: '';
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: calc($globe-height/3);
      border-radius: 3px;
      top: (0 - calc($globe-height/6));
      left: 1px;
    }

    &::after {
      content: '';
      top: (0 - calc($globe-height/2));
      left: $globe-width - 3;
      position: absolute;
      width: $globe-spacing + 12;
      height: (calc($globe-height/3) * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }

    &:last-child::after {
      content: none;
    }

    &:first-child {
      margin-left: -$globe-spacing;
    }
  }
}

@keyframes flash-1 {
  0%,
  100% {
    background: rgba($globe-color-1, 1);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-1, 1);
  }

  50% {
    background: rgba($globe-color-1, $light-off-opacity);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-1, 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: rgba($globe-color-2, 1);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-2, 1);
  }

  50% {
    background: rgba($globe-color-2, $light-off-opacity);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-2, 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: rgba($globe-color-3, 1);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-3, 1);
  }

  50% {
    background: rgba($globe-color-3, $light-off-opacity);
    box-shadow: 0 calc($globe-height/6) $globe-width * 2 $globe-spread rgba($globe-color-3, 0.2);
  }
}
