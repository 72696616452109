@use 'typeface-open-sans' as open-sans; // only works because includePaths option is set in next.config.ts
@use 'typeface-droid-sans-mono' as droid-sans; // only works because includePaths option is set in next.config.ts
@use 'bootstrap-icons/font/bootstrap-icons.css'; // only works because includePaths option is set in next.config.ts
@use './font-family-system';
@use './lightrope';
@use 'react-aspect-ratio/aspect-ratio.css';
@use 'react-gif-player/src/GifPlayer.scss';
@use 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
@use './components/LanguageSwitcher';

@import './variables';
@import 'bootstrap/scss/bootstrap';
@import './theme';

/* stylelint-disable-next-line selector-class-pattern */
.gif_player {
  margin: 1rem;

  img {
    border: 2px solid $gray-500;
    pointer-events: none;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  overflow-y: auto;
}

body {
  background-color: $body-bg;
}

/* stylelint-disable-next-line selector-class-pattern, selector-id-pattern, selector-max-id */
#__next {
  height: 100%;
}

.container,
.container-fluid {
  padding: 0;
}

.main-container {
  height: 100%;
  background-color: $body-bg;
  min-width: 320px;
}

.bi.bi-link-45deg.mdx-link-icon {
  color: $gray-600;
}

/* Override bootstrap for better contrast */
h3,
.h3 {
  font-size: 1.5rem;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.blockquote {
  display: flex;
  min-height: 80px;
  padding: 5px 10px;
  background-color: $blockquote-bg-color;

  > p {
    margin: auto;
  }
}

// Always show spinbox arrows in Chrome
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.noselect {
  -webkit-touch-callout: none;
  user-select: none;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

.truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide-native-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.body-color {
  color: $body-color;
}

.text-decoration-none {
  text-decoration: none;
}

.table-layout-fixed {
  table-layout: fixed;
}

.fixed-bottom {
  position: fixed;
  bottom: 30px;
  z-index: 100;
}

.btn-shadow {
  box-shadow: 4px 4px 10px 1px #222;
}

.icon.fill-red {
  fill: $red;
}

.icon.fill-green {
  fill: $green;
}

.cursor-pointer {
  cursor: pointer;
}

.recharts-tooltip-wrapper {
  z-index: 1000 !important;
}

/* bootstrap/scss/code sets "color: inherit" for only *direct( descendants of an anchor element */
code {
  a & {
    color: inherit;
  }
}
