// Some of the things are borrowed from bootswatch Materia theme
// https://github.com/thomaspark/bootswatch

/* stylelint-disable selector-no-qualifying-type */

@use './variables' as vars;

@mixin ripple($color) {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 0;
    pointer-events: none;
    content: '';
    background-position: 50%;
    background-size: 1000% 1000%;
    border: 0;
    opacity: 0;
    transition:
      background 0.5s,
      opacity 1s;

    @include gradient-radial($color 10%, transparent 10.01%);
  }

  &:active::after {
    background-size: 0 0;
    opacity: 0.2;
    transition: 0s;
  }

  &.disabled,
  &[disabled] {
    &::after {
      display: none;
    }
  }
}

// Navbar ======================================================================

.navbar {
  border: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  padding: 0 1rem;

  &-brand {
    font-size: 24px;
  }

  &-nav .nav-link {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }

  &.bg-dark,
  &.bg-primary {
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='tel'] {
      color: #fff;
      box-shadow: inset 0 -1px 0 rgb(255 255 255 / 50%);

      &:focus {
        box-shadow: inset 0 -2px 0 #fff;
      }

      &::placeholder {
        color: rgb(255 255 255 / 50%);
      }
    }
  }
}

// Buttons =====================================================================

@mixin btn($class, $bg, $color) {
  .btn-#{$class} {
    &:focus {
      background-color: $bg;
      box-shadow: 0 0 0 2px rgb(204 204 204 / 50%);
    }

    &:hover,
    &:active:hover {
      background-color: darken($bg, 6%); /* stylelint-disable-line scss/no-global-function-names, function-no-unknown */
    }

    &:active {
      box-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
    }

    @include ripple($color);
  }

  .btn-outline-#{$class} {
    @include ripple($color);
  }
}

@include btn(primary, vars.$primary, vars.$white);
@include btn(secondary, vars.$secondary, vars.$gray-500);
@include btn(success, vars.$success, vars.$white);
@include btn(info, vars.$info, vars.$white);
@include btn(warning, vars.$warning, vars.$white);
@include btn(danger, vars.$danger, vars.$white);
@include btn(dark, vars.$dark, vars.$white);
@include btn(light, vars.$light, vars.$white);

.btn {
  border: 0;
  box-shadow: 0 1px 4px rgb(0 0 0 / 40%);
  transition: all 0.4s;

  &-link {
    color: vars.$link-color;
    text-transform: none;
    box-shadow: none;

    &:hover,
    &:focus {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      box-shadow: none;
    }

    &.disabled, // Although btn-link is intended for buttons, which want to look like link, I include here a.disable for the sake of consistency
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:active:hover {
        color: $btn-link-disabled-color;
        text-decoration: none;
      }
    }
  }

  &-secondary {
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      color: rgb(0 0 0 / 40%);
      background-color: rgb(0 0 0 / 10%);
      opacity: 1;

      &:hover,
      &:focus {
        background-color: rgb(0 0 0 / 10%);
      }
    }
  }

  &-outline-secondary {
    color: vars.$gray-300;
    border-color: vars.$gray-200;
  }

  &-warning {
    color: vars.$white;
  }

  &.dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }
}

.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 0;
  }

  &-vertical {
    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
      margin-top: 0;
    }
  }

  .btn + .btn,
  .btn + .btn-group > .dropdown-toggle {
    box-shadow: 1px 1px 4px rgb(0 0 0 / 40%);
  }
}

.border-secondary {
  border: 1px solid #dfdfdf !important;
}

// Typography ==================================================================

body,
input,
button {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
}

p {
  margin: 0 0 1em;
}

a {
  transition: all 0.2s;
}

.text-secondary {
  color: vars.$gray-500 !important;
}

// Tables ======================================================================

.table-hover {
  > tbody > tr,
  > tbody > tr > th,
  > tbody > tr > td {
    transition: all 0.2s;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.thead-inverse th {
  color: vars.$white;
  background-color: vars.$primary;
}

// Forms =======================================================================

.col-form-label {
  font-size: 16px;

  &-sm {
    font-size: $font-size-sm;
  }

  &-lg {
    font-size: $font-size-lg;
  }
}

textarea,
textarea.form-control,
input.form-control,
input[type='text'], /* stylelint-disable-line no-descending-specificity */
input[type='password'], /* stylelint-disable-line no-descending-specificity */
input[type='email'], /* stylelint-disable-line no-descending-specificity */
input[type='number'], /* stylelint-disable-line no-descending-specificity */
[type='text'].form-control,
[type='password'].form-control,
[type='email'].form-control,
[type='tel'].form-control,
[contenteditable].form-control {
  background-color: vars.$white;
  border: 1px solid vars.$gray-300;
}

// Navs ========================================================================

.dropdown-menu {
  margin-top: 0;
  border: 0;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}

.nav-tabs {
  .nav-item + .nav-item {
    margin-left: 0;
  }

  .nav-link,
  .nav-link:focus {
    margin-right: 0;
    color: vars.$body-color;
    background-color: transparent;
    border: 0;
    box-shadow: inset 0 -1px 0 #ddd;
    transition: all 0.2s;

    &:hover {
      color: vars.$primary;
      background-color: transparent;
      box-shadow: inset 0 -2px 0 vars.$primary;
    }
  }

  .nav-link.active,
  .nav-link.active:focus {
    color: vars.$primary;
    border: 0;
    box-shadow: inset 0 -2px 0 vars.$primary;

    &:hover {
      color: vars.$primary;
      border: 0;
    }
  }

  .nav-link.disabled {
    box-shadow: inset 0 -1px 0 #ddd;
  }

  &.nav-justified {
    .nav-link, /* stylelint-disable-line no-descending-specificity */
    .nav-link:hover,
    .nav-link:focus,
    .nav-link.active, /* stylelint-disable-line no-descending-specificity */
    .nav-link.active:hover,
    .nav-link.active:focus {
      border: 0;
    }
  }

  .dropdown-menu {
    margin-top: 0;
  }
}

.badge {
  &-secondary {
    color: vars.$white;
    background-color: vars.$gray-500;
  }

  &-light {
    background-color: vars.$gray-200;
  }

  &-warning {
    color: vars.$white;
  }
}

.tag {
  padding: 4px 6px;
}

// Containers ==================================================================

.close {
  line-height: 0.5;
  opacity: 0.6;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.card {
  box-shadow: 0 1px 4px rgb(0 0 0 / 40%);

  &.border-primary,
  &.border-secondary,
  &.border-success,
  &.border-info,
  &.border-warning,
  &.border-danger,
  &.border-light,
  &.border-dark {
    border-width: 1px;
  }
}

.alert-dismissible {
  .close {
    padding: 0.5rem 0.75rem;
  }
}

.carousel {
  &-caption {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
}

.list-group {
  &-item-action.active {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: vars.$white;
    }
  }
}

.modal-content {
  border-radius: 0.2rem;
  box-shadow: 0 6px 36px rgb(0 0 0 / 30%);
}

.popover {
  border: 0;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}
