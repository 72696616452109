@use '../variables' as vars;

.language-switcher {
  margin-top: 3px;

  .language-switcher-menu.dropdown-menu {
    background-color: vars.$body-bg;
    box-shadow: 1px 1px 2px 2px rgba(vars.$gray-600, 0.25);
  }

  .language-switcher-flag {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    background-size: cover;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 2px rgba(vars.$gray-600, 0.25);
  }
}
